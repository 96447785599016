import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Row, Col, Alert, Typography, Form, Input, Button, Divider } from 'antd';
import { TwitterOutlined } from '@ant-design/icons';
const { Footer } = Layout;
import useAuth from '../api/auth';
import { navigate } from 'gatsby';
import HeaderComponent from '../components/header/header';

const { Link } = Typography;

const Login = ({ redirect }) => {
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState();
  const showErrorMsg = msg => {
    setLoginErrorMsg(msg);
    setTimeout(() => {
      setLoginErrorMsg();
    }, 2000);
  };

  const onSubmit = async values => {
    try {
      setLoading(true);
      await login(values);
      setLoading(false);
      navigate(redirect || '/home');
    } catch (e) {
      console.log(e);
      showErrorMsg('Error, usuario o contraseña incorrecto');
      setLoading(false);
    }
  };

  return (
    <Layout className="site-layout-background">
      <HeaderComponent title="Iniciar Sesión" />
      <Divider className="no-border"/>
      <Form name="normal_login" onFinish={onSubmit}>
        <Form.Item
          name="identifier"
          rules={[{ required: true, message: 'Por favor ingrese su correo electrónico' }]}>
          <Input
            className="form-item"
            prefix={<UserOutlined className="site-form-item-icon" />}
            bordered={true}
            placeholder="Email"
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Por favor ingrese su password' }]}>
          <Input.Password
            className="form-item"
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Password"
            bordered={true}
            size="large"
          />
        </Form.Item>

        { loginErrorMsg &&
          <Form.Item>
            <Alert message={loginErrorMsg} type="error" closable banner showIcon />
          </Form.Item>
        }
        <Form.Item>
          <Button htmlType="submit" type="primary" size="large" block loading={loading}>
            Iniciar Sesión
          </Button>
        </Form.Item>
      </Form>
      <Footer className="background-base">
        <Row>
          <Col span={12} offset={6}>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Button shape="circle" size="large">
                  <TwitterOutlined />
                </Button>
              </Col>
              <Col span={8}>
                <Button shape="circle" size="large">
                  <TwitterOutlined />
                </Button>
              </Col>
              <Col span={8}>
                <Button shape="circle" size="large">
                  <TwitterOutlined />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="btn-link">
            <span>¿Ya eres miembro?</span>{' '}
            <Link href="/register" strong>
              Registrate Aqui
            </Link>
          </Col>
        </Row>
      </Footer>
    </Layout>
  );
};

Login.propTypes = {
  data: PropTypes.any,
  redirect: PropTypes.object,
};

Login.defaultProps = {
  data: '',
};

export default Login;
